@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Sen", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.download-button-container {
  text-align: center;
  margin-top: 20px;
}
.download-button-container {
  text-align: center;
  margin-top: 20px;
}

.download-btn {
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid #4caf50; /* Green border */
  color: #4caf50; /* Green text */
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s, border-color 0.3s, background-color 0.3s;
}

.download-btn:hover,
.download-btn:focus {
  color: white;
  background-color: #4caf50; /* Green background on hover */
}

/* Gallery stuff */
.sample-data-section {
  text-align: center;
  /* padding: 40px 20px; */
  border-radius: 8px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  /* margin: 40px; */
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent; /* Set background to transparent */
}

.section-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 30px;
}

/* Ensure no background color for gallery container */
.gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  /* No background-color property here */
}

.gallery-item {
  display: block;
  border: 2px solid transparent;
  border-radius: 8px;
  transition: transform 0.3s, border-color 0.3s;
}

.gallery-item:hover,
.gallery-item:focus {
  transform: scale(1.05); /* Slightly enlarge on hover */
  border-color: #4caf50; /* Green border on hover */
}

.gallery-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

/* header 8 */
.bg-material-ui-blue {
  background-color: white /* #2196F3; /* Material UI Blue */
}

.text-white {
  color: white; /* White text */
}

.text-white:hover, .text-white-700:hover {
  color: #2196F3; /* Lighter blue for hover effect, adjust as needed */
}

/* Custom Styles for Buttons */

/* Base style for all buttons */
.button-base {
  padding: 10px 15px; /* Comfortable padding */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  font-weight: bold; /* Bold font */
  font-size: 1rem; /* Readable font size */
  cursor: pointer; /* Cursor change to indicate clickable */
  transition: background-color 0.2s, transform 0.2s; /* Smooth transitions for interactions */
  margin: 5px; /* Spacing around buttons */
  display: inline-block; /* Ensuring proper display */
  text-align: center; /* Centering text */
  color: white; /* Default text color */
}

/* Hover and Active Styles */
.button-base:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
}

.button-base:active {
  transform: scale(0.95); /* Slight shrink effect on click */
}

/* Specific styles for each button type */
.clear-button {
  background-color: #f44336; /* Reddish tone for clear action */
}

.toggle-button {
  background-color: #ff9800; /* Orange for toggling action */
}

.find-boxes-button {
  background-color: #4caf50; /* Green for positive action */
}

.process-image-button {
  background-color: #2196f3; /* Blue for process action */
}

.dcl-upload-button {
  background-color: #9c27b0; /* Purple for special action */
}

/* Style for disabled state */
.button-base:disabled {
  background-color: #bdbdbd; /* Grayed out */
  color: #757575; /* Darker text */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
  transform: none; /* No transform on hover */
  box-shadow: none; /* No shadow */
}


/* General hover effect for all buttons */
.button-base:hover {
  opacity: 0.9;
}

/* Style for the toggle button */
.toggle-button {
  background-color: #f0ad4e; /* Bootstrap's 'warning' color */
  color: white;
}

/* Style for the 'Clear' button */
.clear-button {
  background-color: #dcdcdc; /* Light gray */
  color: black;
}

/* Style for the 'Find boxes' button */
.find-boxes-button {
  background-color: #5cb85c; /* Bootstrap's 'success' color */
  color: white;
}

/* Style for the 'Process Image' button */
.process-image-button {
  background-color: #0275d8; /* Bootstrap's 'primary' color */
  color: white;
}

/* Additional style for disabled buttons */
.button-base:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

.high-zindex {
  z-index: 100;
  position: relative; /* or absolute/fixed as per your requirement */
}

/* Existing CSS ... */

/* Channel Button Styles */
.channel-button {
  padding: 6px 12px;
  border-radius: 4px;
  border: 2px solid transparent;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
  margin-right: 8px; /* Match horizontal space with .button-base */
  margin-bottom: 0px; /* Match vertical space with .button-base */
  font-weight: bold;
}



.channel-nuclear{
  background-color: #4caf50; /* Green background */
  color: white;
}

.channel-wholecell {
  background-color: #2196f3; /* Blue background */
  color: white;
}

.channel-blank {
  background-color: #f44336; /* Red background */
  color: white;
}

.channel-button:hover {
  opacity: 0.8;
}

.channel-button:active {
  border-color: #333; /* Dark border for active state */
}


/* New styles for channel dropdown */
.channel-dropdown {
  padding: 8px 16px; /* Consistent padding with buttons */
  border-radius: 4px; /* Rounded corners like buttons */
  border: 2px solid #ddd; /* Light gray border */
  background-color: white; /* White background */
  font-weight: bold; /* Bold text */
  color: #555; /* Dark gray text color for visibility */
  margin-right: 8px; /* Horizontal spacing */
  margin-bottom: 8px; /* Vertical spacing */
  cursor: pointer; /* Cursor to indicate it's clickable */
  transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transitions for interactions */
}

.channel-dropdown:hover {
  border-color: #bbb; /* Slightly darker border on hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover for depth */
}

.channel-dropdown:focus {
  border-color: #2196f3; /* Blue border for focus, matching your blue button */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* More pronounced shadow for focus */
  outline: none; /* Remove default outline */
}

.channel-dropdown.channel-nuclear {
  border-color: #4caf50; /* Green border for nuclear */
}

/* Style for when 'Wholecell' is selected */
.channel-dropdown.channel-wholecell {
  border-color: #2196f3; /* Blue border for wholecell */
}


/* You might also want to add specific styles for different channel types if needed */
.quickstart-guide {
  font-size: 15px; /* Smaller font size */
  position: sticky;
  top: 50px;
  z-index: 10;
}

.quickstart-guide h3, .quickstart-guide h4 {
  font-size: 18px; /* Adjust heading sizes accordingly */
}

.quickstart-guide p {
  font-size: 14px; /* Ensure paragraph text is also smaller */
}

code {
  font-family: 'Courier New', Courier, Monaco, Consolas, 'Lucida Console', monospace;
  background-color: #f4f4f4; /* Light grey background */
  color: #d63384; /* Distinct text color, often a dark red or blue */
  padding: 2px 2px; /* Adds some space around the text */
  border-radius: 4px; /* Optional: rounds the corners */
  border: 0.5px solid #ccc; /* Light border for definition */
  font-size: 0.9em; /* Slightly smaller font size */
}
